import React from 'react'
import JotformEmbed from 'react-jotform-embed';


export default function Form() {
    return (
        <div>
            <JotformEmbed src="https://form.jotformeu.com/201086661257152" />
        </div>
    )
}
