const contentss = [
    {
        subtitle: 'Poster Design Competition First Position Winner - Saugat Thapa',
        image: require('../assets/Gallery/Saugat.jpg'),
    },
    {
        subtitle: 'Poster Design Competition First Position Winner - Aavash Mishra',
        image: require('../assets/Gallery/avash.jpg'),
    },
    {
        subtitle: 'Poster Design Competition First Position Winner - Bibesh KC',
        image: require('../assets/Gallery/Bibesh.png'),
    },
    {
        subtitle: 'Poster Design Competition First Position Winner - Akriti Karki',
        image: require('../assets/Gallery/Akriti.jpg'),
    },
]
export default contentss;